import { all } from 'redux-saga/effects'

import { default as AccountSaga } from './Account/sagas';
import { default as AgentSaga } from './Agent/sagas';
import { default as EventSaga } from './Event/sagas';
import { default as CheckinSaga } from './Checkin/sagas';
import { default as AnalyticsSaga } from './Analytics/sagas';

export default function* rootSaga() {
    yield all([
       AccountSaga(),
       EventSaga(),
       AgentSaga(),
       CheckinSaga(),
       AnalyticsSaga(),
    ])
}
