import * as TYPE from './types'

const initialState = {
  admin_log: [],
  admin_asset: null,
  public_agent: null,
  public_asset: null,
  message: "",
  status: 500,
  message_asset: "",
  status_asset: 500,
  message_log: "",
  status_log: 500,
  data_export_log: null,
}

function CheckinReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.CHECKIN_LIST_ASSET_ASYNC:
      return {
        ...state,
        admin_asset: action.payload.data,
        message_asset: action.payload.message,
        status_asset: action.payload.status,
      }
    case TYPE.CHECKIN_LIST_LOG_ASYNC:
      return {
        ...state,
        admin_log: action.payload.data,
        message_log: action.payload.message,
        status_log: action.payload.status,
      }
    case TYPE.CHECKIN_EXPORT_LOG_ASYNC:
      return {
        ...state,
        data_export_log: action.payload.data,
      }
    case TYPE.CHECKIN_UPDATE_ASSET_ASYNC:
      return {
        ...state,
        message_asset: action.payload.message,
        status_asset: action.payload.status,
      }
    case TYPE.CHECKIN_GET_ASSET_ASYNC:
      return {
        ...state,
        public_asset: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.CHECKIN_FIND_AGENT_ASYNC:
      return {
        ...state,
        public_agent: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.CHECKIN_CREATE_LOG_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    default:
      return state
  }
}

export default CheckinReducer