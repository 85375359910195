const mapStateToProps = state => {
  return {
    i18n: state.I18nReducers,
    realtime: state.RealtimeReducers,
    accounts: state.AccountReducers,
    agents: state.AgentReducers,
    events: state.EventReducers,
    checkin: state.CheckinReducers,
    analytics: state.AnalyticsReducers,
  };
};
export default mapStateToProps;