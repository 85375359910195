import * as TYPE from './types'

const dispatchAnalyticsTotalCountAsync = (data) => ({
  type: TYPE.ANALYTICS_TOTAL_COUNT,
  data,
});
const dispatchAnalyticsCheckinDatesAsync = (data) => ({
  type: TYPE.ANALYTICS_CHECKIN_DATES,
  data,
});
const dispatchAnalyticsCheckinHourAsync = (data) => ({
  type: TYPE.ANALYTICS_CHECKIN_HOUR,
  data,
});

export default {
  dispatchAnalyticsTotalCountAsync,
  dispatchAnalyticsCheckinDatesAsync,
  dispatchAnalyticsCheckinHourAsync,
};

export const TotalCountAsync = (payload) => {
  return {
    type: TYPE.ANALYTICS_TOTAL_COUNT_ASYNC,
    payload
  }
}
export const CheckinDatesAsync = (payload) => {
  return {
    type: TYPE.ANALYTICS_CHECKIN_DATES_ASYNC,
    payload
  }
}
export const CheckinHourAsync = (payload) => {
  return {
    type: TYPE.ANALYTICS_CHECKIN_HOUR_ASYNC,
    payload
  }
}