import * as TYPE from './types'

const initialState = {
  token: localStorage.getItem('token'),
  messageUpdateMe: "",
  statusUpdateMe: 200,
  messageLogin: "",
  statusLogin: 200,
  data: [],
  data_notifications: [],
  data_user: null,
  avaliable: undefined,
  message: "",
  status: 500,
}

function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.ACCOUNT_UNMOUNT: 
      return {
        ...state,
        messageUpdateMe: "",
        statusUpdateMe: 200,
        messageLogin: "",
        statusLogin: 200,
        data: [],
        data_notifications: [],
        data_user: null,
        avaliable: undefined,
        message: "",
        status: 500,
      };
    case TYPE.ACCOUNT_LOGIN_ASYNC:
      return {
        ...state,
        data_user: action.payload.data,
        token: action.payload.token,
        messageLogin: action.payload.message,
        statusLogin: action.payload.status,
        messageUpdateMe: "",
        statusUpdateMe: 200,
      }
    case TYPE.ACCOUNT_CREATE_ASYNC:
    case TYPE.ACCOUNT_MULTI_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.ACCOUNT_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.ACCOUNT_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.ACCOUNT_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    // me
    case TYPE.ACCOUNT_UPDATE_ME_ASYNC:
      return {
        ...state,
        data_user: action.payload.data,
        messageUpdateMe: action.payload.message,
        statusUpdateMe: action.payload.status,
      }
    //notification
    case TYPE.ACCOUNT_LIST_NOTIFICATION_ASYNC:
      return {
        ...state,
        data_notifications: action.payload.data,
      }
    case TYPE.ACCOUNT_SEND_NOTIFICATION_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    default:
      return state
  }
}

export default AccountReducer