import * as TYPE from './types'

const initialState = {
  data: [],
  data_export: null,
  card: null,
  message: "",
  status: 500,
}

function AgentReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.AGENT_UNMOUNT:
      return {
        ...state,
        data: [],
        message: "",
        status: 500,
      };
    case TYPE.AGENT_CREATE_ASYNC:
    case TYPE.AGENT_MULTI_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.AGENT_UPDATE_ASYNC:
    case TYPE.CARD_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.AGENT_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.AGENT_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.AGENT_EXPORT_ASYNC:
      return {
        ...state,
        data_export: action.payload.data,
      }
    case TYPE.CARD_LIST_ASYNC:
      return {
        ...state,
        card: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    default:
      return state
  }
}

export default AgentReducer