import * as TYPE from './types'

const initialState = {
  data_total_count: [],
  data_checkin_day: [],
  data_checkin_hour: null,
  message: "",
  status: 500,
}

function AnalyticsReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.ANALYTICS_TOTAL_COUNT_ASYNC:
      return {
        ...state,
        data_total_count: action.payload.data,
      }
    case TYPE.ANALYTICS_CHECKIN_DATES_ASYNC:
      return {
        ...state,
        data_checkin_day: action.payload.data,
      }
    case TYPE.ANALYTICS_CHECKIN_HOUR_ASYNC:
      return {
        ...state,
        data_checkin_hour: action.payload.data,
      }
    default:
      return state
  }
}

export default AnalyticsReducer