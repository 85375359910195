import * as TYPE from './types'

const dispatchCheckinListAssetAsync = (data) => ({
  type: TYPE.CHECKIN_LIST_ASSET,
  data,
});
const dispatchCheckinListLogAsync = (data) => ({
  type: TYPE.CHECKIN_LIST_LOG,
  data,
});
const dispatchCheckinExportLogAsync = (data) => ({
  type: TYPE.CHECKIN_EXPORT_LOG,
  data,
});
const dispatchCheckinUpdateAssetAsync = (data) => ({
  type: TYPE.CHECKIN_UPDATE_ASSET,
  data,
});
const dispatchCheckinGetAssetAsync = (data) => ({
  type: TYPE.CHECKIN_GET_ASSET,
  data,
});
const dispatchCheckinCreateLogAsync = (data) => ({
  type: TYPE.CHECKIN_CREATE_LOG,
  data,
});
const dispatchCheckinFindAgentAsync = (data) => ({
  type: TYPE.CHECKIN_FIND_AGENT,
  data,
});

export default {
  dispatchCheckinListAssetAsync,
  dispatchCheckinListLogAsync,
  dispatchCheckinExportLogAsync,
  dispatchCheckinUpdateAssetAsync,

  dispatchCheckinGetAssetAsync,
  dispatchCheckinCreateLogAsync,
  dispatchCheckinFindAgentAsync,
};

export const ListAssetAsync = (payload) => {
  return {
    type: TYPE.CHECKIN_LIST_ASSET_ASYNC,
    payload
  }
}
export const ListLogAsync = (payload) => {
  return {
    type: TYPE.CHECKIN_LIST_LOG_ASYNC,
    payload
  }
}
export const ExportLogAsync = (payload) => {
  return {
    type: TYPE.CHECKIN_EXPORT_LOG_ASYNC,
    payload
  }
}
export const UpdateAssetAsync = (payload) => {
  return {
    type: TYPE.CHECKIN_UPDATE_ASSET_ASYNC,
    payload
  }
}
export const GetAssetAsync = (payload) => {
  return {
    type: TYPE.CHECKIN_GET_ASSET_ASYNC,
    payload
  }
}
export const CreateLogAsync = (payload) => {
  return {
    type: TYPE.CHECKIN_CREATE_LOG_ASYNC,
    payload
  }
}
export const FindAgentAsync = (payload) => {
  return {
    type: TYPE.CHECKIN_FIND_AGENT_ASYNC,
    payload
  }
}