import * as TYPE from './types'

const dispatchAgentCreateAsync = (data) => ({
  type: TYPE.AGENT_CREATE,
  data,
});
const dispatchAgentMultiCreateAsync = (data) => ({
  type: TYPE.AGENT_MULTI_CREATE,
  data,
});
const dispatchAgentUpdateAsync = (data) => ({
  type: TYPE.AGENT_UPDATE,
  data,
});
const dispatchAgentListAsync = (data) => ({
  type: TYPE.AGENT_LIST,
  data,
});
const dispatchAgentDeleteAsync = (data) => ({
  type: TYPE.AGENT_DELETE,
  data,
});
const dispatchAgentUnmountAsync = (data) => ({
  type: TYPE.AGENT_UNMOUNT,
  data,
});
const dispatchCardUpdateAsync = (data) => ({
  type: TYPE.CARD_UPDATE,
  data,
});
const dispatchCardListAsync = (data) => ({
  type: TYPE.CARD_LIST,
  data,
});
const dispatchAgentExportAsync = (data) => ({
  type: TYPE.AGENT_EXPORT,
  data,
});

export default {
  dispatchAgentCreateAsync,
  dispatchAgentMultiCreateAsync,
  dispatchAgentUpdateAsync,
  dispatchAgentListAsync,
  dispatchAgentDeleteAsync,

  dispatchAgentUnmountAsync,

  dispatchCardUpdateAsync,
  dispatchCardListAsync,

  dispatchAgentExportAsync,
};

export const CreateAsync = (payload) => {
  return {
    type: TYPE.AGENT_CREATE_ASYNC,
    payload
  }
}
export const MultiCreateAsync = (payload) => {
  return {
    type: TYPE.AGENT_MULTI_CREATE_ASYNC,
    payload
  }
}
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.AGENT_UPDATE_ASYNC,
    payload
  }
}
export const ListAsync = (payload) => {
  return {
    type: TYPE.AGENT_LIST_ASYNC,
    payload
  }
}
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.AGENT_DELETE_ASYNC,
    payload
  }
}
export const CardUpdateAsync = (payload) => {
  return {
    type: TYPE.CARD_UPDATE_ASYNC,
    payload
  }
}
export const CardListAsync = (payload) => {
  return {
    type: TYPE.CARD_LIST_ASYNC,
    payload
  }
}
export const ExportAsync = (payload) => {
  return {
    type: TYPE.AGENT_EXPORT_ASYNC,
    payload
  }
}