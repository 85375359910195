import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization, } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncTotalCount(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/analytics/total_count/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCheckinDates(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/analytics/checkin_dates/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCheckinHour(data) {
  const { data: { uuid, date, locale } } = data
  return axios.get(Url.END_POINT + "/api/analytics/checkin_hour/" + uuid + "/" + date + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/

function* actionTotalCount(data) {
  const response = yield call(asyncTotalCount, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Analytics } } = response.data;
      result.data = Analytics;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.TotalCountAsync(result))
}
function* actionCheckinDates(data) {
  const response = yield call(asyncCheckinDates, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Analytics } } = response.data;
      result.data = Analytics;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.CheckinDatesAsync(result))
}
function* actionCheckinHour(data) {
  const response = yield call(asyncCheckinHour, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Analytics } } = response.data;
      result.data = Analytics;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.CheckinHourAsync(result))
}
/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.ANALYTICS_TOTAL_COUNT, actionTotalCount)
  yield takeLatest(TYPE.ANALYTICS_CHECKIN_DATES, actionCheckinDates)
  yield takeLatest(TYPE.ANALYTICS_CHECKIN_HOUR, actionCheckinHour)
}

export default CustomSaga