import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { setPermission, RefreshAuthorization, isPermissionRoot, isPermissionAdmin, isPermissionRootView } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncLogin(data) {
  const { data: { user_name, password, locale } } = data;

  return axios.post(Url.END_POINT + "/api/login",
    {
      user_name,
      password,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCreate(data) {
  const { data: {
    permission = 1,
    user_name,
    full_name = "",
    email = "",
    phone_number = "",
    address = "",
    avatar_img_path,
    event_uuid,
    password,
    confirmPassword,
    locale,
  } } = data;

  return axios.post(Url.END_POINT + "/api/user",
    {
      permission,
      user_name,
      full_name,
      email,
      phone_number,
      address,
      avatar_img_path,
      event_uuid,
      password,
      confirmPassword,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncMultiCreate(data) {
  const { data: {
    excel_file,
    event_uuid,
    locale,
  } } = data;

  return axios.post(Url.END_POINT + "/api/users-excel",
    {
      excel_file,
      event_uuid,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdate(data) {
  const { data: {
    uuid,
    email = "",
    phone_number = "",
    full_name = "",
    identity_card = "",
    address = "",
    password = "",
    confirmPassword = "",
    avatar_img_path = "",
    gifts,
    active = "",
    locale,
  } } = data;

  return axios.put(Url.END_POINT + "/api/user-admin", {
    uuid,
    email,
    phone_number,
    full_name,
    identity_card,
    address,
    avatar_img_path,
    password,
    confirmPassword,
    gifts,
    active,
    locale,
  },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncList(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/users/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { data: { uuid, locale } } = data;
  return axios.delete(Url.END_POINT + "/api/user",
    {
      data: {
        uuid,
        locale,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdateMe(data) {
  const { data: {
    full_name = "",
    image = "",
    oldPassword = "",
    password = "",
    confirmPassword = "",
    locale,
  } } = data;

  return axios.put(Url.END_POINT + "/api/user-me",
    {
      full_name,
      image,
      oldPassword,
      password,
      confirmPassword,
      locale,
    },
    {
      headers: {
        'content-type': 'application/json',
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListNotification(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/notifications-admin/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncSendNotification(data) {
  const { data: { event_uuid, text, users_to_uuid, locale } } = data
  return axios.post(Url.END_POINT + "/api/notifications-admin",
    {
      event_uuid, text, users_to_uuid, locale
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionLogin(data) {
  const response = yield call(asyncLogin, data);
  // console.log(response); return;

  // handler
  let result = {}
  result.message = "Tài khoản hoặc mật khẩu không chính xác";
  result.status = 422;

  if (response) {
    let { Notification: { Message } } = response.data;
    result.message = Message;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Token, User } } = response.data;
      let { Notification: { User: { permission_id, uuid, user_parent_uuid, user_name, full_name, email, avatar_img_path, address, permission_name, view_only } } } = response.data;

      result.token = Token;
      result.data = User;
      localStorage.setItem("token", Token);
      localStorage.setItem("uuid", uuid);
      localStorage.setItem("user_parent_uuid", user_parent_uuid);
      localStorage.setItem("user_name", user_name);
      localStorage.setItem("full_name", full_name);
      localStorage.setItem("email", email);
      localStorage.setItem("avatar_img_path", avatar_img_path);
      localStorage.setItem("address", address);
      localStorage.setItem("permissionName", permission_name);
      localStorage.setItem("z", setPermission(permission_id, view_only));

      // router
      const { history } = data;

      if (isPermissionRoot() || isPermissionRootView() || isPermissionAdmin()) {
        history.push('/app/dashboards/default');
      }
      // else if (isPermissionAdmin()) {
      //   // console.log("supervisor");
      //   history.push('/app/dashboards/supervisor');
      // }
      else {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result) return;
  yield put(actions.LoginAsync(result))
}
function* actionLogout(data) {

  localStorage.removeItem("token");
  localStorage.removeItem("uuid");
  localStorage.removeItem("user_name");
  localStorage.removeItem("full_name");
  localStorage.removeItem("email");
  localStorage.removeItem("avatar_img_path");
  localStorage.removeItem("address");
  localStorage.removeItem("permissionName");
  localStorage.removeItem("z");

  // router
  document.location = '/';
}
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.CreateAsync(result))
}
function* actionMultiCreate(data) {
  const response = yield call(asyncMultiCreate, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.MultiCreateAsync(result))
}
function* actionList(data) {
  const response = yield call(asyncList, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { User_in_event } } = response.data;
      result.data = User_in_event;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ListAsync(result))
}
function* actionUpdate(data) {
  const response = yield call(asyncUpdate, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeleteAsync(result))
}
function* actionUpdateMe(data) {
  const response = yield call(asyncUpdateMe, data);

  // console.log(data, response); return false;

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 404 || response.status === 422) {

      localStorage.setItem("full_name", "ADMIN")
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 200) {
      let { Notification: { User } } = response.data;
      localStorage.setItem("full_name", User.full_name)
      localStorage.setItem("email", User.email)
      localStorage.setItem("address", User.address)
      localStorage.setItem("avatar_img_path", User.avatar_img_path)

      result.data = User;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  // console.log(response);

  yield put(actions.UpdateMeAsync(result))
}
function* actionListNotification(data) {
  const response = yield call(asyncListNotification, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Notification } } = response.data;
      result.data = Notification;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ListNotificationAsync(result))
}
function* actionSendNotification(data) {
  const response = yield call(asyncSendNotification, data);
  // console.log(response)

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Notification } } = response.data;
      result.data = Notification;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.SendNotificationAsync(result))
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.ACCOUNT_LOGIN, actionLogin)
  yield takeLatest(TYPE.ACCOUNT_LOGOUT, actionLogout)

  yield takeLatest(TYPE.ACCOUNT_CREATE, actionCreate)
  yield takeLatest(TYPE.ACCOUNT_MULTI_CREATE, actionMultiCreate)
  yield takeLatest(TYPE.ACCOUNT_UPDATE, actionUpdate)
  yield takeLatest(TYPE.ACCOUNT_LIST, actionList)
  yield takeLatest(TYPE.ACCOUNT_DELETE, actionDelete)

  yield takeLatest(TYPE.ACCOUNT_UPDATE_ME, actionUpdateMe)
  yield takeLatest(TYPE.ACCOUNT_LIST_NOTIFICATION, actionListNotification)
  yield takeLatest(TYPE.ACCOUNT_SEND_NOTIFICATION, actionSendNotification)
}


export default CustomSaga