import { combineReducers } from 'redux'
import { reducers as I18nReducers } from './I18n'
import { reducers as RealtimeReducers } from './Realtime'
import { reducers as AccountReducers } from './Account'
import { reducers as EventReducers } from './Event'
import { reducers as AgentReducers } from './Agent'
import { reducers as CheckinReducers } from './Checkin'
import { reducers as AnalyticsReducers } from './Analytics'

// Functions to catch actions dispatched
export default combineReducers({
    I18nReducers,
    RealtimeReducers,
    AccountReducers,
    EventReducers,
    AgentReducers,
    CheckinReducers,
    AnalyticsReducers,
});