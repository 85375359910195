// CRUD PERMISSION
export const AGENT_CREATE = "AGENT_CREATE"
export const AGENT_CREATE_ASYNC = "AGENT_CREATE_ASYNC"
export const AGENT_MULTI_CREATE = "AGENT_MULTI_CREATE"
export const AGENT_MULTI_CREATE_ASYNC = "AGENT_MULTI_CREATE_ASYNC"
export const AGENT_UPDATE = "AGENT_UPDATE"
export const AGENT_UPDATE_ASYNC = "AGENT_UPDATE_ASYNC"
export const AGENT_LIST = "AGENT_LIST"
export const AGENT_LIST_ASYNC = "AGENT_LIST_ASYNC"
export const AGENT_DELETE = "AGENT_DELETE"
export const AGENT_DELETE_ASYNC = "AGENT_DELETE_ASYNC"

// unmount
export const AGENT_UNMOUNT = "AGENT_UNMOUNT"

// Card
export const CARD_UPDATE = "CARD_UPDATE"
export const CARD_UPDATE_ASYNC = "CARD_UPDATE_ASYNC"
export const CARD_LIST = "CARD_LIST"
export const CARD_LIST_ASYNC = "CARD_LIST_ASYNC"

// Export
export const AGENT_EXPORT = "AGENT_EXPORT"
export const AGENT_EXPORT_ASYNC = "AGENT_EXPORT_ASYNC"