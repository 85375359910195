import React from 'react';
import { toast, ToastContainer } from 'react-toastify'

const useMessage = () => {

    const initMessageToast = (_status, _message, _callbackFunc) => {

        if (_status === 200) {
            if (_message && _message.length) {
                toast.info(_message);
                _callbackFunc();
            }
        } else {
            if (_message && _message.length) {

                if (typeof (_message) == 'object') {
                    _message.forEach(m => toast.error(m, {
                        autoClose: false,
                    }))
                    _callbackFunc();
                }
                else {
                    toast.error(_message);
                }
            }
        }
    }

    return { initMessageToast }
}

export default useMessage;