import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization, } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncListAsset(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/checkin/admin/asset/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListLog(data) {
  const { data: { uuid, page, keyword, locale } } = data
  return axios.get(Url.END_POINT + "/api/checkin/admin/log/" + uuid + "/" + page + "?locale=" + locale + "&keyword=" + keyword,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncExportLog(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/checkin/admin/export/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdateAsset(data) {
  const { data: {
    event_uuid,
    bg_img_path = "",
    fields,
    locale,
  } } = data;

  return axios.put(Url.END_POINT + "/api/checkin/admin/asset", {
    event_uuid,
    fields,
    bg_img_path,
    locale,
  },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetAsset(data) {
  const { data: { event_uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/checkin/public/asset/" + event_uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncFindAgent(data) {
  const { data: { event_uuid, agent_qrcode, type_checkin, locale } } = data
  return axios.get(Url.END_POINT + "/api/checkin/public/find/" + event_uuid + "/" + agent_qrcode + "/" + type_checkin + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCreateLog(data) {
  const { data: {
    event_uuid,
    event_agent_uuid,
    agent_avatar_img_path,
    type_checkin,
    locale,
  } } = data;

  return axios.post(Url.END_POINT + "/api/checkin/public/log",
    {
      event_uuid,
      event_agent_uuid,
      agent_avatar_img_path,
      type_checkin,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/

function* actionListAsset(data) {
  const response = yield call(asyncListAsset, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { CheckinAsset } } = response.data;
      result.data = CheckinAsset;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ListAssetAsync(result))
}
function* actionListLog(data) {
  const response = yield call(asyncListLog, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { CheckinLog } } = response.data;
      result.data = CheckinLog;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ListLogAsync(result))
}
function* actionExportLog(data) {
  const response = yield call(asyncExportLog, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Message } } = response.data;
      result.data = Message;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ExportLogAsync(result))
}
function* actionUpdateAsset(data) {
  const response = yield call(asyncUpdateAsset, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateAssetAsync(result))
}
function* actionGetAsset(data) {
  const response = yield call(asyncGetAsset, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { CheckinAsset } } = response.data;
      result.data = CheckinAsset;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.GetAssetAsync(result))
}
function* actionFindAgent(data) {
  const response = yield call(asyncFindAgent, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { CheckinFindAgent } } = response.data;
      result.data = CheckinFindAgent;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.FindAgentAsync(result))
}
function* actionCreateLog(data) {
  const response = yield call(asyncCreateLog, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.CreateLogAsync(result))
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.CHECKIN_LIST_ASSET, actionListAsset)
  yield takeLatest(TYPE.CHECKIN_LIST_LOG, actionListLog)
  yield takeLatest(TYPE.CHECKIN_EXPORT_LOG, actionExportLog)
  yield takeLatest(TYPE.CHECKIN_UPDATE_ASSET, actionUpdateAsset)
  yield takeLatest(TYPE.CHECKIN_GET_ASSET, actionGetAsset)
  yield takeLatest(TYPE.CHECKIN_FIND_AGENT, actionFindAgent)
  yield takeLatest(TYPE.CHECKIN_CREATE_LOG, actionCreateLog)
}


export default CustomSaga