import React from 'react'
import ReactDOM from 'react-dom/client'

// redux
import rootReducer from './redux'
import rootSaga from './redux/saga'

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { BrowserRouter } from 'react-router-dom'

import 'react-perfect-scrollbar/dist/css/styles.css'
import "dropzone/dist/min/dropzone.min.css"
import 'react-toastify/dist/ReactToastify.min.css'
import 'rsuite/dist/styles/rsuite-default.css';
import '../scss/app.scss'

import App from './App'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

// mount it on the Store
const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
)

// Run the saga
sagaMiddleware.run(rootSaga)

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(<Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
</Provider>)
