import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncCreate(data) {
  const { data: {
    event_uuid,
    fields,
    locale,
  } } = data;

  return axios.post(Url.END_POINT + "/api/agent",
    {
      event_uuid,
      fields,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncMultiCreate(data) {
  const { data: {
    excel_file,
    event_uuid,
    locale,
  } } = data;

  return axios.post(Url.END_POINT + "/api/agents-excel",
    {
      excel_file,
      event_uuid,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdate(data) {
  const { data: {
    uuid,
    avatar_img_path = "",
    active = "",
    fields,
    locale,
  } } = data;

  return axios.put(Url.END_POINT + "/api/agent", {
    uuid,
    fields,
    avatar_img_path,
    active,
    locale,
  },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncList(data) {
  const { data: { uuid, page, keyword, locale } } = data
  return axios.get(Url.END_POINT + "/api/agents/" + uuid + "/" + page + "?locale=" + locale + "&keyword=" + keyword,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { data: { uuid, locale } } = data;
  return axios.delete(Url.END_POINT + "/api/agent",
    {
      data: {
        uuid,
        locale,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncExport(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/agents-export/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCardUpdate(data) {
  const { data: {
    event_uuid,
    bg_img_path = "",
    fields,
    locale,
  } } = data;

  return axios.put(Url.END_POINT + "/api/card", {
    event_uuid,
    bg_img_path,
    fields,
    locale,
  },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCardList(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/cards/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.CreateAsync(result))
}
function* actionMultiCreate(data) {
  const response = yield call(asyncMultiCreate, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.MultiCreateAsync(result))
}
function* actionList(data) {
  const response = yield call(asyncList, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Agents } } = response.data;
      result.data = Agents;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ListAsync(result))
}
function* actionExport(data) {
  const response = yield call(asyncExport, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Message } } = response.data;
      result.data = Message;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ExportAsync(result))
}
function* actionUpdate(data) {
  const response = yield call(asyncUpdate, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeleteAsync(result))
}
function* actionCardList(data) {
  const response = yield call(asyncCardList, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Cards } } = response.data;
      result.data = Cards;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.CardListAsync(result))
}
function* actionCardUpdate(data) {
  const response = yield call(asyncCardUpdate, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.CardUpdateAsync(result))
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.AGENT_CREATE, actionCreate)
  yield takeLatest(TYPE.AGENT_MULTI_CREATE, actionMultiCreate)
  yield takeLatest(TYPE.AGENT_UPDATE, actionUpdate)
  yield takeLatest(TYPE.AGENT_LIST, actionList)
  yield takeLatest(TYPE.AGENT_DELETE, actionDelete)
  yield takeLatest(TYPE.AGENT_EXPORT, actionExport)

  yield takeLatest(TYPE.CARD_UPDATE, actionCardUpdate)
  yield takeLatest(TYPE.CARD_LIST, actionCardList)
}


export default CustomSaga