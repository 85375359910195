// CRUD PERMISSION
export const CHECKIN_LIST_ASSET = "CHECKIN_LIST_ASSET"
export const CHECKIN_LIST_ASSET_ASYNC = "CHECKIN_LIST_ASSET_ASYNC"
export const CHECKIN_LIST_LOG = "CHECKIN_LIST_LOG"
export const CHECKIN_LIST_LOG_ASYNC = "CHECKIN_LIST_LOG_ASYNC"
export const CHECKIN_UPDATE_ASSET = "CHECKIN_UPDATE_ASSET"
export const CHECKIN_UPDATE_ASSET_ASYNC = "CHECKIN_UPDATE_ASSET_ASYNC"
// export
export const CHECKIN_EXPORT_LOG = "CHECKIN_EXPORT_LOG"
export const CHECKIN_EXPORT_LOG_ASYNC = "CHECKIN_EXPORT_LOG_ASYNC"

// public
export const CHECKIN_GET_ASSET = "CHECKIN_GET_ASSET"
export const CHECKIN_GET_ASSET_ASYNC = "CHECKIN_GET_ASSET_ASYNC"
export const CHECKIN_FIND_AGENT = "CHECKIN_FIND_AGENT"
export const CHECKIN_FIND_AGENT_ASYNC = "CHECKIN_FIND_AGENT_ASYNC"
export const CHECKIN_CREATE_LOG = "CHECKIN_CREATE_LOG"
export const CHECKIN_CREATE_LOG_ASYNC = "CHECKIN_CREATE_LOG_ASYNC"

